// import $ from 'jquery';
import Swiper from 'swiper/bundle';
// import 'swiper/css';
import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import remodal from 'remodal';
// gsap scrollTrigerr
gsap.registerPlugin(ScrollTrigger);
/**
 * 現在一から上スクロールでクラスふよ
 */
console.log(remodal)
/**
 * @desc スクロールであるポイントを超えたらクラスを付与
 * @param {*} targetEl  
 * @param {*} togglePointEl 
 * @param {*} toggleClass 
 */
const scrollInToggleClass = (targetEl, togglePointEl, toggleClass) => {

  if (togglePointEl) {
    const observer1 = new IntersectionObserver((entries) => {
      for (const e of entries) {
        if (e.isIntersecting) {
          targetEl.classList.remove(toggleClass);
        } else {
          targetEl.classList.add(toggleClass);
        }
      }
    });
    observer1.observe(togglePointEl);
  } else {
    if (targetEl) {
      targetEl.classList.add(toggleClass);
    }
  }
};


scrollInToggleClass(document.querySelector(".js-header"), document.querySelector(".js-hero"), "is-active")
// scrollInToggleClass(document.querySelector(".js-header-fresh"), document.querySelector(".js-hero"), "is-active")

/**
 * @desc 一文字づつ文字をラップする
 * @param {*} target 
 * @returns 一文字つづspanでラップしたテキスト
 */
const spanWrapText = (target) => {
  const nodes = [...target.childNodes];
  let returnText = '';

  for (const node of nodes) {
    if (node.nodeType == 3) {
      const text = node.textContent.replace(/\r?\n/g, '');
      const splitText = text.split('');
      for (const char of splitText) {
        returnText += `<span>${char}</span>`;
      }
    } else {
      returnText += node.outerHTML;
    }
  }
  return returnText;
};

/* */

const hero = document.querySelector(".js-hero")
const header = document.querySelector(".js-header")
if (hero) {
  const heroTl = gsap.timeline();
  heroTl.from(hero.querySelector('.hero__mv img'), {
    scale: 1.2,
    duration: 1.75
  });
  gsap.fromTo(header, {
    y: "-100%",
    opacity: 0
  }, {
    duration: 1.5,
    opacity: 1,
    y: 0
  });
  gsap.fromTo(hero.querySelector('.hero__main picture'), {
    opacity: 0,
    y: 50,
  }, {
    duration: 1.7,
    y: 0,
    opacity: 1,
  });
}

const pageHero = document.querySelector('.js-page-hero');
if (pageHero) {
  const pageHeroTl = gsap.timeline();
  gsap.set(pageHero.querySelector('.c-hero__title-ruby'), {
    opacity: 0
  });
  gsap.set(pageHero.querySelector('.c-hero__title'), {
    opacity: 0
  });
  pageHeroTl.from(pageHero.querySelector('img'), {
    opacity: 0,
    scale: 1.25,
    duration: .8
  });
  pageHeroTl.to(pageHero.querySelector('.c-hero__title-ruby'), {
      delay: 0,
      opacity: 1,
  });
  pageHeroTl.to(pageHero.querySelector('.c-hero__title'), {
      opacity: 1,
  });
  
}
const fadeInEls = gsap.utils.toArray('.js-fadein-effect1');
if (fadeInEls) {
  fadeInEls.forEach((box, index) => {
    gsap.fromTo(box, {
      y: 30,
      opacity: 0,
    }, {
      y: 0,
      opacity: 1,
      duration: 1.25,
      scrollTrigger: {
        trigger: box,
        start: 'top bottom',
        end: 'bottom top',
      }
    });
  });
}
const scaleEffect = gsap.utils.toArray('.js-scale-effect1');
if (scaleEffect) {
  scaleEffect.forEach((box, index) => {
    gsap.fromTo(box, {
      y: 60,
      scale: 1.25,
      opacity: 0,
    }, {
      y: 0,
      scale : 1,
      opacity: 1,
      duration: 1.5,
      scrollTrigger: {
        trigger: box,
        start: 'top bottom',
        end: 'bottom top',
      }
    });
  });
}
const rollEffect = gsap.utils.toArray('.js-x-effect1');
if (rollEffect) {
  rollEffect.forEach((box, index) => {
    gsap.fromTo(box, {
      x: "25%",
    }, {
      x: 0,
      duration: .75,
      scrollTrigger: {
        trigger: box,
        start: 'top bottom',
        end: 'bottom top',
      }
    });
  });
}
const rollEffect2 = gsap.utils.toArray('.js-x-effect2');
if (rollEffect2) {
  rollEffect2.forEach((box, index) => {
    gsap.fromTo(box, {
      x: "-25%",
    }, {
      x: 0,
      duration: .75,
      scrollTrigger: {
        trigger: box,
        start: 'top bottom',
        end: 'bottom top',
      }
    });
  });
}
const yEffect = gsap.utils.toArray('.js-y-effect1');
if (yEffect) {
  yEffect.forEach((box, index) => {
    gsap.fromTo(box, {
      y: "25%",
    }, {
      y: 0,
      duration: .75,
      scrollTrigger: {
        trigger: box,
        start: 'top bottom',
        end: 'bottom top',
      }
    });
  });
}
const yEffect2 = gsap.utils.toArray('.js-y-effect2');
if (yEffect2) {
  yEffect2.forEach((box, index) => {
    gsap.fromTo(box, {
      y: "-25%",
    }, {
      y: 0,
      duration: .75,
      scrollTrigger: {
        trigger: box,
        start: 'top bottom',
        end: 'bottom top',
      }
    });
  });
}
const staggerEffect1 = gsap.utils.toArray('.js-stagger-effect1');
if (staggerEffect1) {
  // staggerEffect1.forEach((box, index) => {
    gsap.fromTo(staggerEffect1, {
      y: 50,
      opacity: 0,
    }, {
      y: 0,
      opacity: 1,
      stagger: 0.1,
      duration: .75,
      scrollTrigger: {
        trigger: staggerEffect1,
        start: 'top bottom',
        end: 'bottom top',
      }
    });
  // });
}
const staggerEffect2 = gsap.utils.toArray('.js-stagger-effect2');
if (staggerEffect2) {
  // staggerEffect2.forEach((box, index) => {
    gsap.fromTo(staggerEffect2, {
      y: 70,
      opacity: 0,
    }, {
      y: 0,
      opacity: 1,
      stagger: 0.25,
      duration: .75,
      scrollTrigger: {
        trigger: staggerEffect2,
        start: 'top bottom',
        end: 'bottom top',
      }
    });
  // });
}
const staggerEffect3 = gsap.utils.toArray('.js-stagger-effect3');
if (staggerEffect3) {
    gsap.fromTo(staggerEffect3, {
      y: -150,
      opacity: 0,
    }, {
      y: 0,
      opacity: 1,
      stagger: .25,
      duration: .75,
      scrollTrigger: {
        trigger: staggerEffect3,
        start: 'top bottom',
        end: 'bottom top',
      }
    });
}
const staggerEffect4 = gsap.utils.toArray('.js-stagger-effect4');
if (staggerEffect4) {
    gsap.fromTo(staggerEffect4, {
      y: -150,
      opacity: 0,
    }, {
      y: 0,
      opacity: 1,
      stagger: .25,
      duration: .75,
      scrollTrigger: {
        trigger: staggerEffect4,
        start: 'top bottom',
        end: 'bottom top',
      }
    });
}

// tl.from(spans, {
//   opacity: 0,
//   duration: 0.01,
//   stagger: 0.08,
// });

const topRecruitLabel = gsap.utils.toArray('.top-recruit__label');
if (topRecruitLabel) {
  topRecruitLabel.forEach((box, index) => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: box,
        start: 'top 90%',
      }
    });
    tl.fromTo(box.querySelector('.bg'), {
      scaleX: 0,
    }, {
      scaleX: 1,
      duration: .5,
    });
    tl.fromTo(box.querySelector('.txt'), {
      opacity: 0,
      y: -15,
    }, {
      delay: .5,
      duration: .5,
      y: 0,
      opacity: 1,
    })
  });
}

document.querySelectorAll(".c-section-title").forEach((e) => {
  const mainText = e.querySelector(".c-section-title__ruby");
  const subText = e.querySelector(".c-section-title__main");
  mainText.innerHTML = spanWrapText(mainText)
  const spans = mainText.querySelectorAll('span');
  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: e,
      start: 'top 90%',
    }
  });
  tl.from(spans, {
    opacity: 0,
    duration: 0.01,
    stagger: 0.08,
  });
  tl.from(subText, {
    opacity: 0,
    y: 15,
    delay: 0.25,
    duration: 0.2,
  })
})

// const scrollDownToggleClass = new ScrollDownToggleClass(document.getElementById('header'));
// window.addEventListener('scroll', () => {
//   scrollDownToggleClass.toggleClass()
// });


$(function(){
  $(window).on("load", function () {
    var url = $(location).attr("href");
    if (url.indexOf("#") != -1) {
      var anchor = url.split("#");
      if (anchor.length > 1 && anchor[anchor.length - 1]) {
        var target = $("#" + anchor[anchor.length - 1]);
        if (target.length) {
          let margin = document.querySelector("#header") ? document.querySelector("#header").getBoundingClientRect().height : 0;
          var pos = Math.floor(target.offset().top) - margin;
          $("html, body").animate({ scrollTop: pos }, 500);
        }
      }
    }
  });
  $('.js-smooth-scroll').on('click',function(){
    var speed = 500;
    var href= $(this).attr("href").split('#');
    if (href.length >= 2) {
        href ="#"+href[1];
    }
    var target = $(href == "#" || href == "" ? 'html' : href);
    if (target.length!=0) {
        var position = target.offset().top;
        var margin = document.querySelector("#header") ? document.querySelector("#header").getBoundingClientRect().height : 0;

        position-=(margin + 20);
        $("html, body").animate({scrollTop:position}, speed, "swing");
        return false;
    }
  });
  $('.js-header-open').on('change', function(){
    $('.js-header').toggleClass('is-open')
  })
  $('.js-close-header').on('click', function(){
    $('.js-menu-check').prop('checked', false)
  })
  $('.js-accordion').on('click', function(){
    $(this).toggleClass('is-active')
    $(this).next().slideToggle()
  })
})

/****/
//

// mv animation

// $(function(){

//   $('.js-toggle').on('click', function(){
//     $(this).toggleClass('is-active')
//     $(this).next().slideToggle()
//   })
// })
/***
 * swiper
 */
window.addEventListener("DOMContentLoaded", () => {
  const freshInterview = document.querySelector(".js-slider--fresh-interview")
  if (freshInterview){
    const infiniteSlider = new Swiper(freshInterview, {
      // loop: true,
      // loopedSlides: freshInterview.querySelector('.swiper-slide').length,
      slidesPerView: 'auto',
      speed: 500,
      navigation: {
        nextEl: freshInterview.querySelector(".swiper-button-next"),
        prevEl: freshInterview.querySelector(".swiper-button-prev")
      }
      // autoplay: {
      //   delay: 1,
      // },
    });
  }

  const hero = document.querySelector(".js-hero-slider")
  if (hero) {
    const heroSlider = new Swiper(hero, {
      loop: true,
      effect: "fade", 
      loopedSlides: 3,
      speed: 2000,
      slidesPerView: 'auto',
      centeredSlides: true,
      autoplay: {
        delay: 4000, // 4秒後に次のスライドへ
        disableOnInteraction: false, // ユーザーが操作しても自動再生を継続
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      }
    });
  }
});

// (function(){
//   const heroMv = document.querySelector('.hero__mv')
//   const count = 400; 
//   for (let i = 0 ; i < 400; i++) {
//     let block = document.createElement('div')
//     block.classList.add('box')
//     heroMv.appendChild(block)
//   }
//   const boxs = gsap.utils.toArray('.box');
//   if (boxs) {
//     gsap.from(boxs, {
//       // delay.05
//       top: 80,
//       left: 80,
//       stagger: .005,
//       // scale : 150
//       // y: 10,
//       // opacity: 1,
//     })
//   }
// })()

